console.log("%cMade by Deystro → https://instagram.com/deystro", "background: black; color: white; padding: 1ch 2ch; border-radius: 2rem;");
const buttons = document.querySelectorAll('.c_hover');
buttons.forEach(button => {
    button.addEventListener('mousemove', e => {
    const x = event.offsetX;
    const y = event.offsetY;

    button.style.setProperty('--x', `${x}px`);
    button.style.setProperty('--y', `${y}px`);
  });
});