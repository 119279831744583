import barba from '@barba/core';
import { preloaderInit } from './home-intro.js';

history.scrollRestoration = "manual";

function load_js(fileName){
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = fileName + '.js';
    head.appendChild(script);
    console.log(script);
}

barba.init({
    debug: true,
    logLevel: 'error',
	schema: {
		prefix: 'data-page',
		wrapper: 'wrap'
	},
    views: [
        {
            namespace: 'home',
            beforeEnter(data) {
                const trigger = data.trigger;

                function is_dom_element(obj) {
                    return !!(obj && obj.nodeType === 1);
                }       

                if ( data.trigger === 'back' || is_dom_element(trigger) ) {
                    preloaderInit(false);
                }else{
                    preloaderInit();
                }
            },
            afterEnter(data) {
                // Ensure scroll to top for every navigation
                window.scrollTo(0, 0);
                // Trigger any parallax or page-specific JS here
                console.log('Parallax refresh or other JS for home' + data.current.namespace);
            },
            beforeLeave(data) {
                // Custom exit animation for home
                console.log('Custom exit animation for home' + data.current.namespace);
            }
        },
    ]
});

barba.hooks.after((data) => {
    const pageName = data.next.namespace;
    const body = document.body;

    window.scrollTo(0, 0);

    // Caricamento dinamico dello script JS
    const newScript = document.createElement("script");
    const oldScript = document.querySelector(".page_script");
    newScript.src = `/wp-content/themes/deystro/assets/js/${pageName}.min.js`;
    newScript.className = "page_script";
    newScript.defer = true;
    if (oldScript) oldScript.remove();
    body.appendChild(newScript);

    // Caricamento dinamico dello stile CSS
    const newStyle = document.createElement("link");
    const oldStyle = document.getElementById("deystro-css");
    newStyle.type = 'text/css';
    newStyle.rel = 'stylesheet';
    newStyle.href = `/wp-content/themes/deystro/assets/css/${pageName}.min.css`;
    newStyle.id = "deystro-css";
    if (oldStyle) oldStyle.remove();
    document.head.appendChild(newStyle);

    const preloader = document.querySelector(".home_intro");
    preloader.remove();
});