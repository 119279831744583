import { gsap } from "gsap";

const animationOptions = {
    ease: "expo.inOut"
};

const introAnimation = () => {
    const tl = gsap.timeline({
        defaults: {
            ease: animationOptions.ease,
            duration: 1
        }
    });

    tl.to(".intro_title", {
        duration: 1,
        y: 0,
        autoAlpha: 1,
        delay: 0.5
    });

    tl.to(".intro__background--left, .intro__background--right", {
		scaleX: 1
	});
	
    tl.to(".intro__background--left, .intro__background--right", {
		scaleY: 0,
		transformOrigin: "top center"
	});

    tl.to(".intro_title", {
		duration: 1,
		y: -60,
		autoAlpha: 0
	},"-=0.6");

    tl.to(".home_intro", {
		y: "-100%"
	},"-=0.4");

    return tl;
};

const fadeInElements = (elements) => {
    const tl = gsap.timeline();

    tl.from(elements, {
        duration: 1,
        ease: animationOptions.ease,
        y: "20px",
        autoAlpha: 0,
        stagger: 0.1,
		clearProps: "all",
    });

    return tl;
};

const skewInElements = (elements) => {
    const tl = gsap.timeline();

    tl.from(elements, {
        duration: 1,
        delay: 0.2,
        ease: animationOptions.ease,
        autoAlpha: 0,
        y: 40
    });

    return tl;
};

export const preloaderInit = (value = true) => {
    const master = gsap.timeline({
        paused: false,
        delay: 0.2
    });

    if( value ){
        master
        .add(introAnimation())
        .add(fadeInElements(".container-logo"))
        .add(skewInElements(".item_link"), "-=.1");
    }else{
        master
        .add(skewInElements(".item_link"));    
    }
}
